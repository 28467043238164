import { FC } from "react";
import { MobileVersion } from "./MobileVersion";
import { DesktopVersion } from "./DesktopVersion";
import { useInvoices } from "api/assetOwner/assetOwner.endpoints";
import { isEmptyArray } from "helpers/array";
import CustomPlaceholder from "components/CustomPlaceholder";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface Props {
  assetOwnerId: number;
  loaderSize: "medium" | "page";
}
export const Invoices: FC<Props> = ({ assetOwnerId, loaderSize }: Props) => {
  const { t } = useTranslation();

  // Get invoices
  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
  } = useInvoices(assetOwnerId);

  if (isLoadingInvoices || isErrorInvoices || isEmptyArray(invoices)) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isErrorInvoices}
          isLoading={isLoadingInvoices}
          isEmpty={!isLoadingInvoices && !isErrorInvoices}
          icon={<DocumentTextIcon />}
          errorMessage={t(`subHeader.errorFetchingInvoices`)}
          emptyDataMessage={t(`subHeader.noInvoicesFU`)}
          loaderSize={loaderSize}
        />
      </div>
    );
  }

  return (
    <>
      {/* { Visible on screens < 640 px */}
      <MobileVersion invoices={invoices ?? []} />
      {/* Visible on screens >= 640 px */}
      <DesktopVersion invoices={invoices ?? []} />
    </>
  );
};
