import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { FilterMenu } from "./FilterMenu";
import { SearchField } from "./SearchField";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  FilterDTO,
  FilterWithLabelDTO,
  InfiniteQueryResult,
} from "../../api/pagination/pagination.type";
import { ToolBar } from "./Toolbar";
import { THead } from "./THead";
import { TableStyle } from "./TableStyle";
import { TBody } from "./TBody";
import { Title } from "./Title";
import qs from "qs";

interface Props<T extends object> {
  paginationQuery: InfiniteQueryResult<T>;
  columns: ColumnDef<T>[];
  onSelectRow?: (data: T) => void;
  columnVisibility?: Record<string, boolean>;
  setPaginationUrl: React.Dispatch<React.SetStateAction<string>>;
  title?: string;
  searchPlaceholder?: string;
  hasToolbar?: boolean;
  offSetToTriggerPagination?: number;
  createColumnFilter: (filter: FilterDTO[]) => FilterWithLabelDTO[];
}

export const Table = <T extends { id?: number }>({
  paginationQuery,
  columns,
  onSelectRow,
  columnVisibility,
  setPaginationUrl,
  title = "",
  searchPlaceholder = "",
  hasToolbar = true,
  offSetToTriggerPagination = 700,
  createColumnFilter,
}: Props<T>): React.ReactElement => {
  // State used to store the sorting state of the table
  const [sorting, setSorting] = useState<SortingState>([]);

  // Flag to track whether a fetch is in progress and only allow one fetch at a time (i.e. we use throttling here)
  const isFetchingRef = useRef(false);

  // State used to store the width of the scrollbar and update header's width on its resize
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  // Reference to the container of the table's body element for the infinite scrolling logic down below
  const tableContainerRef = useRef<HTMLDivElement>(null);

  // Destructure the data from the paginationQuery to have relevant data for the table
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isRefetching } =
    paginationQuery;

  // Flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page?.items) ?? [],
    [data],
  );

  // Getting updated filters, if any, from the server. Checked only first page, because filters are static (same for each page)
  const filters = useMemo(() => data?.pages?.[0]?.filters ?? [], [data]);

  // Called on scroll to fetch more pages as the user scrolls and reaches bottom of the table body
  const fetchMoreOnBottomReached = useCallback(
    (tableContainerRef?: HTMLDivElement | null) => {
      if (tableContainerRef) {
        const { scrollHeight, scrollTop, clientHeight } = tableContainerRef;
        if (
          scrollHeight - scrollTop - clientHeight < offSetToTriggerPagination &&
          hasNextPage &&
          !isFetchingRef.current &&
          !isFetchingNextPage
        ) {
          isFetchingRef.current = true;
          fetchNextPage().finally(() => {
            isFetchingRef.current = false;
          });
        }
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  // Update scrollbar width on resize to maintain header-body alignment
  const updateScrollbarWidth = useCallback(() => {
    if (tableContainerRef.current) {
      setScrollbarWidth(
        tableContainerRef.current.offsetWidth -
          tableContainerRef.current.clientWidth,
      );
    }
  }, []);

  useEffect(() => {
    const tableContainerElement = tableContainerRef.current;
    if (tableContainerElement) {
      const resizeObserver = new ResizeObserver(updateScrollbarWidth);
      resizeObserver.observe(tableContainerElement);

      return () => resizeObserver.disconnect();
    }
  }, [updateScrollbarWidth]);

  // Attach scroll event to the container
  useEffect(() => {
    const tableContainerElement = tableContainerRef.current;

    if (tableContainerElement) {
      const handleScroll = () =>
        fetchMoreOnBottomReached(tableContainerElement);
      tableContainerElement.addEventListener("scroll", handleScroll);

      return () => {
        tableContainerElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [fetchMoreOnBottomReached]);

  // Update pagination url when sorting changes
  useEffect(() => {
    setPaginationUrl((queryString) => {
      const parsedQueryString = qs.parse(queryString);
      return qs.stringify(
        {
          ...parsedQueryString,
          sortId: sorting.length > 0 ? sorting[0].id : undefined,
          sortOrder:
            sorting.length > 0 ? (sorting[0].desc ? "desc" : "asc") : undefined,
        },
        { arrayFormat: "repeat" },
      );
    });
  }, [sorting, setPaginationUrl]);

  // Set up table props
  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    manualPagination: true,
    manualFiltering: true,
    debugTable: false,
  });

  // Scroll to the top of table when sorting changes
  const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
    setSorting(updater);
    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex?.(0);
    }
  };

  // Since this table option is derived from table row model state, we use the table.setOptions utility
  table.setOptions((prev) => ({
    ...prev,
    onSortingChange: (updater) => {
      if (isRefetching) return;
      handleSortingChange(updater);
    },
  }));

  const { rows } = table.getRowModel();

  // Virtualizer for the table rows to improve performance
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 48,
    getScrollElement: () => tableContainerRef.current,
    overscan: 2, // number of rows to render above and below the visible area
  });

  return (
    <div className="w-full p-4 rounded-2xl flex flex-col bg-white h-max-full min-h-12 shadow">
      {(title || hasToolbar) && (
        <div className="flex-shrink-0 h-8 justify-between items-center flex mb-4">
          <Title title={title} />
          {hasToolbar && (
            <ToolBar>
              <SearchField
                placeholder={searchPlaceholder}
                setPaginationUrl={setPaginationUrl}
              />
              {filters.length !== 0 && (
                <FilterMenu
                  filters={createColumnFilter(filters)}
                  setPaginationUrl={setPaginationUrl}
                />
              )}
            </ToolBar>
          )}
        </div>
      )}
      <div className="flex-grow overflow-hidden bg-white w-full rounded-lg">
        <TableStyle>
          <THead table={table} scrollbarWidth={scrollbarWidth} />
          <TBody
            rowVirtualizer={rowVirtualizer}
            rows={rows}
            tableContainerRef={tableContainerRef}
            onSelectRow={onSelectRow}
          />
        </TableStyle>
      </div>
    </div>
  );
};
