import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Table } from "../tableBeta/Table";
import { useTranslation } from "react-i18next";
import {
  FilterDTO,
  FilterWithLabelDTO,
  InfiniteQueryResult,
} from "../../api/pagination/pagination.type";
import { InstallerItemDTO } from "../../api/installer/installer.dto";
import { Badge } from "../tags/Badge";
import {
  InstallerCanInviteFilterStatus,
  InstallerStatus,
} from "../../enums/installerStatus.enum";

interface Props {
  paginationQuery: InfiniteQueryResult<InstallerItemDTO>;
  setPaginationUrl: Dispatch<SetStateAction<string>>;
  onInstallerClick: (installerId: number, distributorId: number) => void;
  createColumnFilter: (filter: FilterDTO[]) => FilterWithLabelDTO[];
}

export const InstallerTable: FC<Props> = ({
  paginationQuery,
  setPaginationUrl,
  onInstallerClick,
  createColumnFilter,
}) => {
  const { t } = useTranslation();

  const handleRowClick = (row: InstallerItemDTO) => {
    if (row) onInstallerClick(row.id, row.distributorId);
  };

  // Table columns definition
  const columns: ColumnDef<InstallerItemDTO>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t(`installerList.Company`),
        accessorFn: (row: InstallerItemDTO) => (row.name ? `${row.name}` : ""),
      },
      {
        accessorKey: "organizationNumber",
        header: t(`installerList.Organization number`),
        accessorFn: (row: InstallerItemDTO) =>
          row.organizationNumber ? `${row.organizationNumber}` : "",
      },
      {
        accessorKey: "city",
        header: t(`installerList.City`),
        accessorFn: (row: InstallerItemDTO) => (row.city ? `${row.city}` : ""),
      },
      {
        accessorKey: "status",
        header: t(`installerList.Status`),
        cell: (row) => (
          <Badge
            text={row.getValue() as string}
            color={
              row.getValue() === InstallerStatus.Pending ? "blue" : "green"
            }
          />
        ),
      },
      {
        accessorKey: "canInviteAssetOwners",
        header: t(`installerList.Can invite asset owners`),
        accessorFn: (row: InstallerItemDTO) =>
          row?.canInviteAssetOwners
            ? InstallerCanInviteFilterStatus.Yes
            : InstallerCanInviteFilterStatus.No,
        cell: (row) => (
          <Badge
            text={row.getValue() as string}
            color={
              row.getValue() === InstallerCanInviteFilterStatus.Yes
                ? "green"
                : "red"
            }
          />
        ),
      },
    ],
    [],
  );

  return (
    <Table
      columns={columns}
      paginationQuery={paginationQuery}
      onSelectRow={handleRowClick}
      setPaginationUrl={setPaginationUrl}
      title={`${t("installerList.Installers")}`}
      searchPlaceholder={`${t("installerList.SearchForInstallers")}`}
      createColumnFilter={createColumnFilter}
    />
  );
};
