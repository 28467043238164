import { ColumnFilter } from "./pagination.type";

export function createFilterParams(array: ColumnFilter[]): {
  [key: string]: string[];
} {
  const params: { [key: string]: string[] } = {};
  array.forEach((item) => {
    params[item.id] = item.value;
  });
  return params;
}
